import React from "react"
import styled, { css } from "styled-components"
import { breakpoints, media } from "~/styles/breakpoints"
import * as Icon from "react-feather"

const toPascalCase = text => {
  return text && text.replace(/(^\w|-\w)/g, clearAndUpper)
}

const clearAndUpper = text => {
  return text && text.replace(/-/, "").toUpperCase()
}

export default ({
  to,
  outline,
  compact,
  light,
  label,
  icon,
  className,
  onClick,
  target,
  href,
}) => {
  const IconTag = Icon[toPascalCase(icon)]

  return (
    <Button
      {...(to && { href: to })}
      {...(target === "_blank" && {
        rel: "noopener",
      })}
      target={target}
      as={to ? "a" : undefined}
      outline={outline}
      compact={compact}
      light={light}
      className={className}
      onClick={onClick}
    >
      {label}
      {icon && <IconTag size={20} style={{ fill: "none" }} />}
    </Button>
  )
}

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  height: 60px;
  padding: 0 30px;
  position: relative;
  background: #3faf9d;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.05em;
  color: #fff;
  transition: 0.15s;

  svg {
    opacity: 0.5;
    margin-left: 20px;
    margin-right: -5px;
  }

  &:hover {
    background: #19927e;
  }

  ${p =>
    p.outline &&
    css`
      background: none;
      border: 2px solid #3faf9d;
      color: #3faf9d;

      &:hover {
        background: #3faf9d;
        color: #fff;
      }
    `}

  ${p =>
    p.compact &&
    css`
      padding-left: 0;
      padding-right: 0;
      background: none;
      color: #3faf9d;

      &:hover {
        background: none;
        color: #097c69;
      }
    `}
    
  ${p =>
    p.light &&
    css`
      color: #fff;

      &:hover {
        opacity: 0.7;
        color: #fff;
      }
    `}

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 100%;

    svg {
      position: absolute;
      right: 35px;
    }
  }
`
